import { useEffect, useState } from "react"
import "./ForestApp.css"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import AuthContext from "../ReactContexts/AuthContext"
// Initialize Firebase within the app
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import firebaseConfig from "../Firebase/FirebaseConfig"

import { getAuth, GoogleAuthProvider, onAuthStateChanged, User } from "firebase/auth"

import FirebaseWriter from "../Firebase/FirebaseWriter"
// Enable persistence is enabled by default in firebase
import { getDatabase, ref } from "firebase/database"
import ForestLanding from "./ForestLanding"
import ForestPrivateRoute from "./ForestPrivateRoute"
import LocalForestView from "./LocalForestView/LocalForestView"
import usePersonName from "../Components/CustomReactHooks/UsePersonName"
import useForestName from "../Components/CustomReactHooks/UseForestName"
import ForestWriter from "./ForestWriter"
import { WhichPlexusInterface } from "../ReactContexts/PostContext"
import VoicesDashboard from "../Components/AdminStuff/VoicesDashboard/VoicesDashboard"
import LocalForestWrapper from "./LocalForestWrapper"

//Firebase global variables

export const appShortcutInstructionsKey = "app-shortcut-instructions-seen"
export const app = initializeApp(firebaseConfig)
export const database = getDatabase(app)
export const analytics = getAnalytics(app)
export const auth = getAuth()
export const provider = new GoogleAuthProvider()

export interface SingleThoughtBreadcrumb {
  id: string
}
export type ThoughtBreadcrumbsI = SingleThoughtBreadcrumb[]
export let ThoughtBreadcrumbs: ThoughtBreadcrumbsI = []

//for email verification link setup
// export const actionCodeSettings = {
//   // URL you want to redirect back to. The domain (www.example.com) for this
//   // URL must be in the authorized domains list in the Firebase Console.
//   url: "https://plexus.earth/",
//   // This must be true.
//   handleCodeInApp: true,
// }
//initialize it but don't define it yet
export let forestWriter = new ForestWriter()

//a global variable storing data about the logged-in person (tend to avoid "user" language. only two industries where customers are called "users" are high tech and hard drugs.)
// export let person: User

// top level plexus react component
function ForestApp() {
  const [person, setPerson] = useState<User>()
  const [personName, setPersonName] = useState<string>()

  //authentication callback
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("logged in!", { user })
        setPerson(user)
      }
    })
  }, [])

  //initialize forest backend writer, no person name yet
  useEffect(() => {
    if (!forestWriter?.databaseRef && person) {
      const db = getDatabase()
      const databaseRef = ref(db, `p/forum/`)
      //const person name? //TODO come back to this , initialize personBucket if doesn't already exist, etc.
      forestWriter.initialize(
        databaseRef,
        person.uid,
        person.email,
        undefined,
        undefined,
        WhichPlexusInterface.FOREST
      )
    }
  }, [person])
  const forestName = useForestName(person)
  useEffect(() => {
    if (forestName) forestWriter.setName(forestName)
  }, [forestName])

  return (
    <AuthContext.Provider value={{ person, setPerson, personName, setPersonName }}>
      <BrowserRouter>
        <Routes>
          {/* /p is the path for any bounded domain, including the main domain */}
          <Route path="/" element={<ForestPrivateRoute person={person} />}></Route>
          <Route path="/p" element={<Navigate to={"/"} />}></Route>
          <Route path="/thought/:thoughtId" element={<LocalForestWrapper />}></Route>
          <Route path="/thought/" element={<Navigate to={"/"} />} />
          <Route path="/dash/" element={<VoicesDashboard />} />
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  )
}

export default ForestApp
