import React, { useContext, useEffect, useState } from "react"
import { NextOrPrev } from "../ExistingPaths/ExistingPaths"
import ForestThought from "../ForestThought/ForestThought"
import ForestPathCaption from "./ForestPathCaption/ForestPathCaption"
import "./ForestPath.css"
import { auth, forestWriter, provider } from "../../ForestApp"
import LineBetweenDivs from "../LineBetweenDivs"
import QueenThought from "../QueenThought/QueenThought"
import LocalForestContext from "../LocalForestContext"
import { findDirectedEdgeBetweenPosts } from "../../../Logic/ConnectionLogic"
import { ensureNewPathEmailForest } from "../../../SendEmail"
import AuthContext from "../../../ReactContexts/AuthContext"
import { signInWithPopup } from "firebase/auth"
import { useNavigate } from "react-router-dom"
import { send } from "@emailjs/browser"
import { keyForQueen } from "../LocalForestView"

const ForestPath = ({ nextOrPrev, forestPath }: { nextOrPrev: NextOrPrev; forestPath }) => {
  const [caption, setCaption] = useState<string>()
  const { queenThought } = useContext(LocalForestContext)
  const { person } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(() => {
    forestWriter.getCaptionFromEdges(forestPath?.edges, nextOrPrev).then((e) => setCaption(e))
  })

  const handleThoughtClick = () => {
    const thought = forestPath?.thought
    if (!person) {
      signInWithPopup(auth, provider)
      return
    }

    //step
    const sendNotifEmail = forestPath?.edges.length == 0
    forestWriter.takeForestStep(queenThought, thought, sendNotifEmail)
    //navigate
    //force reload
    if (thought?.id)
      // window.location.href = `${window.location.protocol}//${window.location.host}/thought/${thought.id}`
      navigate("/thought/" + thought?.id)
  }

  const handleCaptionClick = () => {
    //determine if there's already an edge with this author
    const stepEdge = forestWriter.findEdgeFromThisPerson(forestPath.edges)
    //add/delete edge accordingly
    if (stepEdge) {
      //delete: TODO
      // forestWriter.deleteConnection(queenThought.)
    } else {
      //add
      const sendNotifEmail = forestPath?.edges.length == 0
      forestWriter.takeForestStep(queenThought, forestPath?.thought, sendNotifEmail)
    }
  }

  //draw line
  const [active, setActive] = useState(false)
  return (
    <div
      className={"forest-path " + nextOrPrev}
      key={forestPath?.thought?.id + keyForQueen(queenThought)}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <LineBetweenDivs
        key={"line-between-" + queenThought.id + "-and-" + forestPath?.thought.id}
        {...{
          id1: "thought-" + queenThought.id,
          id2: "thought-" + forestPath?.thought.id,
          strokeWidth: forestPath.edges.length + (active ? 0.5 : 0),
          // dashed: forestPath.edges.length == 0,
        }}
      />

      <ForestPathCaption
        {...{
          caption,
          empty: forestPath.edges.length == 0 ? "empty" : undefined,
          handleCaptionClick,
        }}
      />
      <ForestThought
        {...{
          thought: forestPath.thought,
          providedThoughtClickHandler: handleThoughtClick,
        }}
      />
    </div>
  )
}

export default ForestPath
