import React, { useContext } from "react"
import { ForestPathType } from "../../ForestTypes"
import ForestPath from "../ForestPath/ForestPath"
import ForestThought from "../ForestThought/ForestThought"
import LocalForestContext from "../LocalForestContext"
import { keyForQueen } from "../LocalForestView"
import "./ExistingPaths.css"

export enum NextOrPrev {
  NEXT = "NEXT",
  PREV = "PREV",
}
export function createUndefinedArray(size: number): Array<undefined> {
  return new Array(size).fill(undefined)
}
const ExistingPaths = ({
  nextOrPrev,
  paths,
}: {
  nextOrPrev: NextOrPrev
  paths: ForestPathType[]
}) => {
  const { queenThought } = useContext(LocalForestContext)
  return (
    <div
      className={"existing-paths " + nextOrPrev}
      key={nextOrPrev + "-paths-for-queen-" + keyForQueen(queenThought)}
    >
      {paths?.map((forestPath) => (
        <ForestPath
          {...{ nextOrPrev, forestPath }}
          key={forestPath?.thought?.id + keyForQueen(queenThought)}
        />
      ))}
    </div>
  )
}

export default ExistingPaths
