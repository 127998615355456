import { useMemo } from "react"
import { SingleConnectionUpdateForAPerson, TextPost } from "../../../ReactContexts/PostContext"
import "./Inbox.css"
import InboxItem from "./InboxItem/InboxItem"

export interface InboxItemInterface {
  sourceThought: TextPost
  targetThought: TextPost
  edge: SingleConnectionUpdateForAPerson
  primaryAction: Function
  primaryActionTaken: boolean
}

const Inbox = ({ items }: { items: InboxItemInterface[] | undefined }) => {
  // Ensure items exist and have a defined edge with a timestamp, then sort them
  const sortedItems = useMemo(() => {
    return items?.sort((a, b) => {
      const timestampA = a.edge?.timestamp
      const timestampB = b.edge?.timestamp

      if (!timestampA || !timestampB) {
        return 0 // Can't sort items without timestamps
      }

      return timestampB - timestampA
    })
  }, [items])
  return sortedItems && sortedItems.length > 0 ? (
    <div className="inbox-container">
      {sortedItems.map((item) => (
        <InboxItem
          item={item}
          key={`${item?.edge?.sourceId}-${item?.edge?.timestamp}-inbox-item`}
        />
      ))}
    </div>
  ) : (
    <div>presenting connections...</div>
  )
}

export default Inbox

export function filterArrayToJustHaveUniqueItems(array: any[], isEqual: Function) {
  return array.filter((value, index, self) => {
    return self.findIndex((item) => isEqual(item, value)) === index
  })
}
