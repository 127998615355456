import React from "react"
import "./ForestPathCaption.css"

const ForestPathCaption = ({
  caption,
  empty,
  invisible,
  handleCaptionClick,
}: {
  caption: string
  empty?: "empty"
  invisible?: true
  handleCaptionClick?: () => any
}) => {
  return (
    <div
      className={"forest-path-caption " + empty + (invisible ? " invisible " : "")}
      onDoubleClick={handleCaptionClick}
    >
      {invisible ? "hello" : caption}
    </div>
  )
}

export default ForestPathCaption
