import { Tree, TreeEvergreen, TreePalm, TreeStructure, WindowsLogo } from "@phosphor-icons/react"
import { signOut } from "firebase/auth"
import React, { useEffect, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import useForestThought from "../../Components/CustomReactHooks/UseForestThought"
import { abbreviateByWords } from "../../Components/SimplePlexusComponents/PostList/FeedItem"
import { abbreviate } from "../../util"
import { auth, forestWriter } from "../ForestApp"
import {
  ForestPathType,
  ForestThoughtType,
  SAMPLE_FOREST_PATH,
  SAMPLE_FOREST_THOUGHT,
} from "../ForestTypes"
import { shadeOfGray } from "./LineBetweenDivs"
import LocalForestContext from "./LocalForestContext"
import "./LocalForestView.css"
import NextPaths from "./NextPaths/NextPaths"
import PrevPaths from "./PrevPaths/PrevPaths"
import QueenThought from "./QueenThought/QueenThought"

/**
 * The main forest app view (for exploration)
 * Query everything from here: the main thought, the previous paths, the next paths
 * @returns
 */
const LocalForestView = () => {
  const { thoughtId } = useParams()

  const queenThought = useForestThought(thoughtId)
  const navigate = useNavigate()
  //Get prev/next paths
  const [nextPaths, setNextPaths] = useState<ForestPathType[]>()
  const [prevPaths, setPrevPaths] = useState<ForestPathType[]>()

  useEffect(() => {
    forestWriter.getNextPaths(queenThought).then((refreshedNextPaths) => {
      console.log("next paths", refreshedNextPaths)
      setNextPaths(refreshedNextPaths)
    })
    forestWriter.getPrevPaths(queenThought).then((refreshedPrevPaths) => {
      setPrevPaths(refreshedPrevPaths)
    })

    const pageTitle = abbreviate(queenThought?.text, 30)
    if (pageTitle) document.title = pageTitle
  }, [queenThought])

  //record visit when URL changes
  useEffect(() => {
    if (forestWriter.personId)
      forestWriter.updateLastTraversedByAuthor(thoughtId, forestWriter.personId)
  }, [thoughtId])

  if (!thoughtId) return <Navigate to="/thought/forest-origin" />

  return (
    <LocalForestContext.Provider value={{ queenThought, prevPaths, nextPaths }}>
      <div className="local-forest-view">
        <i
          style={{
            padding: "1ex",
            cursor: "pointer",
            position: "fixed",
            top: 0,
            right: 0,
            backgroundColor: shadeOfGray(30),
            borderRadius: "3pt",
            margin: "1ex",
            zIndex: "2",
          }}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            navigate("/")
          }}
        >
          <TreeEvergreen size={20} color={shadeOfGray(170)} weight="thin" />
        </i>
        <PrevPaths key={"prev-" + keyForQueen(queenThought)} />
        <QueenThought key={"queen-" + keyForQueen(queenThought)} />
        <NextPaths key={"next-" + keyForQueen(queenThought)} />
      </div>
    </LocalForestContext.Provider>
  )
}

export default LocalForestView

export const keyForQueen = (queenThought: ForestThoughtType): string =>
  (queenThought?.id ?? "") + "-" + (queenThought?.text ?? "")
