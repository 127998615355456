import { createContext, useContext, useState, FunctionComponent, ReactNode } from "react"

interface AudioPlayerContextState {
  playAudio: (audioRef: HTMLAudioElement, postId: string) => void // Updated to include postId
  stopAudio: () => void
  currentlyPlayingId: string | null
  setCurrentlyPlayingId: React.Dispatch<React.SetStateAction<string | null>>
}

const AudioPlayerContext = createContext<AudioPlayerContextState | undefined>(undefined)

interface AudioPlayerProviderProps {
  children: ReactNode
}

// This component wraps its children in audio player context provider
export const AudioPlayerProvider: FunctionComponent<AudioPlayerProviderProps> = ({ children }) => {
  const [currentPlaying, setCurrentPlaying] = useState<HTMLAudioElement | null>(null)
  const [currentlyPlayingId, setCurrentlyPlayingId] = useState<string | null>(null)

  // Function to play a specific audio
  const playAudio = (audioRef: HTMLAudioElement, postId: string) => {
    if (currentPlaying) {
      currentPlaying.pause()
    }
    audioRef.playbackRate = 1.1
    audioRef.play()
    setCurrentPlaying(audioRef)
    setCurrentlyPlayingId(postId) // keep track of which audio is playing
  }

  const stopAudio = () => {
    if (currentPlaying) {
      currentPlaying.pause()
      setCurrentPlaying(null)
      setCurrentlyPlayingId(null)
    }
  }

  return (
    <AudioPlayerContext.Provider value={{ playAudio, stopAudio, currentlyPlayingId, setCurrentlyPlayingId }}>
      {children}
    </AudioPlayerContext.Provider>
  )
}

// Custom hook to use the audio player context
export const useAudioPlayer = (): AudioPlayerContextState => {
  const context = useContext(AudioPlayerContext)
  if (!context) {
    throw new Error("useAudioPlayer must be used within an AudioPlayerProvider")
  }
  return context
}
