import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./Components/App"

//analytics via logrocket
import LogRocket from "logrocket"
import ForestLanding from "./Forest/ForestLanding"
import ForestApp from "./Forest/ForestApp"
LogRocket.init("wp5nlu/plexus")
//@ts-ignore
export const isAppShortcut = window?.navigator?.standalone
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(<ForestApp />)

//maybe helps with mobile?
const rootElement = document.querySelector(".root")
const viewPortH = rootElement?.getBoundingClientRect()?.height
const windowH = window.innerHeight
const browserUiBarsH = viewPortH - windowH
//@ts-ignore
if ("style" in (rootElement ?? {})) rootElement.style.height = `calc(100vh - ${browserUiBarsH}px)`
