import React, { useContext, useEffect, useState } from "react"
import "./NewPathButton.css"
import "../ForestThought/ForestThought.css"
import { Plus } from "@phosphor-icons/react"
import LocalForestContext from "../LocalForestContext"
import { auth, forestWriter, provider } from "../../ForestApp"
import e from "cors"
import ForestPathCaption from "../ForestPath/ForestPathCaption/ForestPathCaption"
import { useNavigate } from "react-router-dom"
import AuthContext from "../../../ReactContexts/AuthContext"
import { signInWithPopup } from "firebase/auth"

const NewPathButton = () => {
  const { queenThought } = useContext(LocalForestContext)
  const { person } = useContext(AuthContext)
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const promptForNewPath = () => {
    //check if logged in
    if (!person) {
      signInWithPopup(auth, provider)
      return
    }

    const newThoughtText = window.prompt("Add anything", "")
    if (newThoughtText) forestWriter.addNewPath(queenThought, newThoughtText)
    // path.then((path) => navigate("/thought/" + path.thought.id))
  }
  useEffect(() => {
    document.onkeydown = (e) => {
      if (!open && e.key === "Shift") {
        e.preventDefault()
        promptForNewPath()
        setOpen(false)
      } else if (e.key === "Enter") setOpen(false)
    }
  }, [])
  return (
    <div className="existing-paths NEXT">
      <div className="forest-path NEXT">
        {/* simulating a caption for consistent height */}
        <ForestPathCaption caption="" invisible={true} />
        <div className="new-path-button" onClick={promptForNewPath}>
          <Plus size={24} />
        </div>
      </div>
    </div>
  )
}

export default NewPathButton

function isAlphanumeric(char: string): boolean {
  return /^[a-z0-9\s]+$/i.test(char)
}
