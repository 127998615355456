import { useEffect, useState } from "react"
import { get, getDatabase, onValue, ref, set } from "firebase/database"
import FirebaseWriter from "../../Firebase/FirebaseWriter"
import { PersonFirebaseBucket } from "../../Firebase/FirebaseDatabaseInterfaces"
import { User } from "firebase/auth"
import { ForestThoughtType } from "../../Forest/ForestTypes"

//Draft of Forest Name hook
function useForestThought(id: string) {
  const [thought, setThought] = useState<ForestThoughtType>()
  useEffect(() => {
    const db = getDatabase()
    const personNameRef = ref(db, "p/" + "forum" + "/nodes/" + id)
    const unsubscribeOnValue = onValue(personNameRef, (snap) => {
      if (snap.exists()) {
        setThought(snap.val())
      }
    })
    return unsubscribeOnValue
  }, [id])

  return thought
}

export default useForestThought
