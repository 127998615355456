// const sgMail = require("@sendgrid/mail")
import emailjs from "@emailjs/browser"
import { logEvent } from "firebase/analytics"
import { analytics } from "./Components/App"
import { getAllReplies } from "./Components/Feed/checkIfMutual"
import { abbreviateByWords } from "./Components/SimplePlexusComponents/PostList/FeedItem"
import { thoughtIsReply } from "./Firebase/ReplyUtilities"
import { TextPost } from "./ReactContexts/PostContext"

export const ensureNewPathEmailForest = (
  queenThought: TextPost,
  newPathThought: TextPost,
  clickerId: string,
  clickerName: string
) => {
  // Don't allow get notifications from yourself
  if (newPathThought.authorId === clickerId) return

  //Don't do this check anymore
  //check that it's the first reply and that the parent thought is a root thought
  //send more emails, don't check for this condition anymore.
  // if (getAllReplies(parentThought).length > 0 || thoughtIsReply(parentThought)) return

  //if it is the first reply, send email
  return sendNewPathEmailForest(
    queenThought.authorName,
    newPathThought.authorName,
    queenThought.text,
    newPathThought.text,
    queenThought.authorEmail,
    newPathThought.authorEmail,
    queenThought.id,
    newPathThought.id,
    queenThought.title,
    newPathThought.title,
    clickerId,
    clickerName
  )
}

export const sendReplyEmailForest = (parentThought: TextPost, replyThought: TextPost) => {
  // Don't allow get notifications from yourself
  if (parentThought.authorId === replyThought.authorId) return

  //Don't do this check anymore
  //check that it's the first reply and that the parent thought is a root thought
  //send more emails, don't check for this condition anymore.
  // if (getAllReplies(parentThought).length > 0 || thoughtIsReply(parentThought)) return

  //if it is the first reply, send email
  return sendFirstReplyEmailForest(
    parentThought.authorName,
    replyThought.authorName,
    parentThought.text,
    replyThought.text,
    parentThought.authorEmail,
    replyThought.authorEmail,
    parentThought.id,
    replyThought.id,
    parentThought.title,
    replyThought.title
  )
}

export const sendEmailIfFirstReply = (parentThought: TextPost, replyThought: TextPost) => {
  // Don't allow get notifications from yourself
  if (parentThought.authorId === replyThought.authorId) return

  //check that it's the first reply and that the parent thought is a root thought
  //send more emails, don't check for this condition anymore.
  if (getAllReplies(parentThought).length > 0 || thoughtIsReply(parentThought)) return

  //if it is the first reply, send email
  return sendFirstReplyEmail(
    parentThought.authorName,
    replyThought.authorName,
    parentThought.text,
    replyThought.text,
    parentThought.authorEmail,
    replyThought.authorEmail,
    parentThought.id,
    replyThought.id,
    parentThought.title,
    replyThought.title
  )
}

function sendFirstReplyEmail(
  toName: string = "anonymous",
  fromName: string = "anonymous",
  toThought: string = "text hidden",
  fromThought: string = "text hidden",
  toEmail: string,
  fromEmail: string = "forum",
  toIDthought: string,
  fromIDthought: string,
  toThoughtTitle: string,
  fromThoughtTitle
) {
  if (toEmail && fromEmail)
    emailjs.send(
      "default_service",
      // Need to change template!
      "template_icmpkas",
      {
        toName,
        fromName,
        toThought,
        fromThought,
        toEmail,
        fromEmail,
        toIDthought,
        fromIDthought,
        toThoughtTitle,
        fromThoughtTitle: fromThoughtTitle ?? abbreviateByWords(fromThought, 10),
      },
      "VFXAIIMaqEenjBrC9"
    )

  logEvent(analytics, "send-reply-email", {
    timestamp: Date.now(),
    nameA: toName,
    nameB: fromName,
    thoughtA: toThought,
    thoughtB: fromThought,
    emailA: toEmail,
    emailB: fromEmail,
    IDthoughtA: toIDthought,
    IDthoughtB: fromIDthought,
  })
}

function sendFirstReplyEmailForest(
  toName: string = "anonymous",
  fromName: string = "anonymous",
  toThought: string = "text hidden",
  fromThought: string = "text hidden",
  toEmail: string,
  fromEmail: string = "forum",
  toIDthought: string,
  fromIDthought: string,
  toThoughtTitle: string,
  fromThoughtTitle
) {
  if (toEmail && fromEmail)
    emailjs.send(
      "default_service",
      // Need to change template!
      "template_o2qvmo7",
      {
        toName,
        fromName,
        toThought,
        fromThought,
        toEmail,
        fromEmail,
        toIDthought,
        fromIDthought,
        toThoughtTitle,
        fromThoughtTitle: fromThoughtTitle ?? abbreviateByWords(fromThought, 10),
      },
      "VFXAIIMaqEenjBrC9"
    )

  logEvent(analytics, "send-forest-reply-email", {
    timestamp: Date.now(),
    nameA: toName,
    nameB: fromName,
    thoughtA: toThought,
    thoughtB: fromThought,
    emailA: toEmail,
    emailB: fromEmail,
    IDthoughtA: toIDthought,
    IDthoughtB: fromIDthought,
  })
}

function sendNewPathEmailForest(
  queenName: string = "anonymous",
  outboundName: string = "anonymous",
  queenThought: string = "text hidden",
  outboundThought: string = "text hidden",
  queenEmail: string,
  outboundEmail: string = "forum",
  queenID: string,
  outboundID: string,
  queenThoughtTitle: string,
  outboundThoughtTitle,
  clickerId,
  clickerName
) {
  if (queenEmail && outboundEmail)
    emailjs.send(
      "default_service",
      // Need to change template!
      "template_k9grxbh",
      {
        queenName,
        outboundName,
        queenThought,
        outboundThought,
        queenEmail,
        outboundEmail,
        queenID,
        outboundID,
        queenThoughtTitle,
        outboundThoughtTitle: outboundThoughtTitle ?? abbreviateByWords(outboundThought, 10),
        clickerId,
        clickerName,
      },
      "VFXAIIMaqEenjBrC9"
    )

  logEvent(analytics, "send-forest-reply-email", {
    timestamp: Date.now(),
    nameA: queenName,
    nameB: outboundName,
    thoughtA: queenThought,
    thoughtB: outboundThought,
    emailA: queenEmail,
    emailB: outboundEmail,
    IDthoughtA: queenID,
    IDthoughtB: outboundID,
  })
}
