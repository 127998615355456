import { signInWithPopup } from "firebase/auth"
import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import AuthContext from "../../../ReactContexts/AuthContext"
import { auth, forestWriter, provider } from "../../ForestApp"
import { ForestThoughtType } from "../../ForestTypes"
import LocalForestContext from "../LocalForestContext"
import { keyForQueen } from "../LocalForestView"
import "./ForestThought.css"

const ForestThought = ({
  isQueen,
  thought,
  providedThoughtClickHandler,
}: {
  isQueen?: true
  thought: ForestThoughtType
  providedThoughtClickHandler?: () => any
}) => {
  const navigate = useNavigate()
  const { queenThought } = useContext(LocalForestContext)
  const { person } = useContext(AuthContext)

  if (!thought) return <></>
  const handleThoughtClick = providedThoughtClickHandler
    ? providedThoughtClickHandler
    : () => undefined
  const text = thought?.text
  return (
    <div
      className={"forest-thought " + (isQueen ? " isQueen" : " isClickable")}
      onClick={handleThoughtClick}
      id={"thought-" + thought?.id}
      key={keyForQueen(queenThought) + "-thought"}
    >
      <span className="forest-thought-author-name">{thought.authorName ?? ""}</span>
      {text}
    </div>
  )
}

export default ForestThought
