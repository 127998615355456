import { backendGetThoughtSuggestionsArray } from "../../Firebase/FirebaseFunctionPointers"
import FirebaseWriter from "../../Firebase/FirebaseWriter"
import { thoughtIsReply } from "../../Firebase/ReplyUtilities"
import { forestWriter } from "../../Forest/ForestApp"
import { TextPost } from "../../ReactContexts/PostContext"
import { getPrompts } from "../AdminStuff/OldHackyAdminPanel/HackyAdmin"
import { simpleBackendWriter } from "../SimplePlexusComponents/SimpleApp"

export const SUGGESTIONS_SIMILARITY_THRESHOLD = 0.86
export type SuggestedThoughtInfo = {
  rootThought: TextPost
  suggestedThought: TextPost
  score: number
  timestamp?: number // what is timestamp supposed to mean here?
}

//filter out all invalid suggestions
interface SuggestedThoughtPineconeData {
  id: string
  score: number
  //only integrarted for thoughts made after 6/12/23, for now (until migration)
  metadata?: {
    authorEmail: string
    authorId: string
    authorName: string
    id: string
    text: string
    timestamp: number
  }
}
interface SuggestedThoughtPineconeDataWithThought extends SuggestedThoughtPineconeData {
  thought: TextPost
}
/**
 * takes as input a list of the person's thoughts, as well as all the thoughts, as well as the embeddings
 * @param myOwnThoughts should just be og thoughts, not replies

 * @returns list of related enough thoughts, in order
 */
export default async function getSuggestionsForOneThought(
  myThoughts: TextPost[],
  placeId: string,
  includeBelowThreshold?: true,
  includeRepliesInSuggestions?: boolean,
  includeSameAuthor?: boolean,
  authorsToFilterBy?: string[],
  onlyAudio?: boolean,
  retryCount: number = 0, // we try to retrieve a max of three times
  maxRetries: number = 5 // max number of retries to avoid potential infinite loop
): Promise<SuggestedThoughtInfo[]> {
  const myThought = myThoughts[0]

  let thoughtsRelatedToOwn: { originalID: string; suggestions: { matches: any } }[]
  try {
    thoughtsRelatedToOwn = await backendGetThoughtSuggestionsArray(
      [myThought.id],
      150,
      placeId,
      authorsToFilterBy,
      onlyAudio
    )
  } catch (e) {
    console.error(e)
    throw e
  }

  // Check if suggestions are empty, and if we haven't exceeded the max number of retries
  if (
    retryCount < maxRetries &&
    (thoughtsRelatedToOwn.length === 0 ||
      thoughtsRelatedToOwn.some((thought) => thought.suggestions.matches.length === 0))
  ) {
    try {
      // Ensure embeddings then retry getting suggestions by recursively calling the current function
      await forestWriter.ensureEmbeddings(
        myThought.text,
        myThought.authorId,
        myThought.id,
        placeId,
        myThought
      )

      // Rerun the function with increased retry count
      return getSuggestionsForOneThought(
        myThoughts,
        placeId,
        includeBelowThreshold,
        includeRepliesInSuggestions,
        includeSameAuthor,
        authorsToFilterBy,
        onlyAudio,
        retryCount + 1, // Increment the retry count
        maxRetries
      )
    } catch (e) {
      console.error(e)
      throw e // could be a default value later or just emptyarr
    }
  }

  // Loops through all of the results returned by the API (pinecone/firebase)
  if (thoughtsRelatedToOwn.length === 0) return
  const suggestionsResults: SuggestedThoughtPineconeData[] =
    thoughtsRelatedToOwn[0].suggestions?.matches
  const suggestedIds = suggestionsResults?.map((data) => data?.id ?? undefined)
  const suggestedThoughtsPromise: Promise<TextPost[]> = FirebaseWriter.queryByIds(suggestedIds)
  //suggested thoughts
  const bigPromise = suggestedThoughtsPromise.then(async (suggestedThoughts: TextPost[]) => {
    // Using the original ID sent to the API, finds the original thought in the post list
    //for verbosity
    if (thoughtsRelatedToOwn.length === 0) return undefined

    const authorUids = [...new Set(suggestedThoughts.map((s) => s?.authorId))]
    // const lastLogins = await simpleBackendWriter.getLastLogins(authorUids)
    //make a richer data object, including the entire thought too
    const withThoughts = suggestionsResults.map((suggestionResult) => {
      return {
        ...suggestionResult,
        thought: suggestedThoughts?.filter((e) => suggestionResult.id === e.id)[0],
      }
    })

    //filter out invalid suggs
    const filteredSuggestions: SuggestedThoughtPineconeDataWithThought[] = withThoughts.filter(
      (thisSuggestedThoughtData: SuggestedThoughtPineconeDataWithThought) => {
        const suggestedThought = thisSuggestedThoughtData.thought
        if (!isValidSuggestedThought(thisSuggestedThoughtData)) return false

        return (
          isDifferentThought(suggestedThought, myThought) &&
          hasDifferentAuthor(suggestedThought, myThought, includeSameAuthor) &&
          meetsScoreThreshold(thisSuggestedThoughtData.score, includeBelowThreshold) &&
          isRootThoughtType(suggestedThought, includeRepliesInSuggestions)
          // &&
          // isRecentlyActiveAuthor(lastLogins, suggestedThought)
        )
      }
    )

    // Formatted
    const suggestionsForThisThought = formatSuggestionsForThought(myThought, filteredSuggestions)
    // Order the suggestions (include text length bias)
    const ordered = suggestionsForThisThought
    // const ordered = orderSuggestions(suggestionsForThisThought)
    const filteredOrdered = filterTopThreeSuggestionsPerAuthor(ordered)
    return filteredOrdered
  })
  return bigPromise
}

// This function will be responsible for formatting the suggestions
const formatSuggestionsForThought = (
  myThought: TextPost,
  filteredSuggestions: SuggestedThoughtPineconeDataWithThought[]
): SuggestedThoughtInfo[] => {
  return filteredSuggestions.map((thisSuggestedThought) => {
    return {
      rootThought: myThought,
      suggestedThought: thisSuggestedThought.thought,
      score: thisSuggestedThought.score,
    }
  })
}

//Checks if this thought is a reply
const checkIfReply = (thought: TextPost) => {
  const prompts = getPrompts(thought)
  return !(!prompts || !prompts.length || prompts.length === 0)
}

const isValidSuggestedThought = (
  suggestedThoughtData: SuggestedThoughtPineconeDataWithThought
): boolean => !!suggestedThoughtData.thought

const isDifferentThought = (suggestedThought: TextPost, myThought: TextPost): boolean =>
  suggestedThought?.id !== myThought?.id

const hasDifferentAuthor = (
  suggestedThought: TextPost,
  myThought: TextPost,
  includeSameAuthor?: boolean
): boolean => includeSameAuthor || myThought?.authorEmail !== suggestedThought?.authorEmail

const meetsScoreThreshold = (score: number, includeBelowThreshold?: true): boolean =>
  includeBelowThreshold || score >= SUGGESTIONS_SIMILARITY_THRESHOLD

const isRootThoughtType = (
  suggestedThought: TextPost,
  includeRepliesInSuggestions?: boolean
): boolean => {
  const oldReplyType = !checkIfReply(suggestedThought)
  const newReplyType = includeRepliesInSuggestions || !thoughtIsReply(suggestedThought)
  return oldReplyType && newReplyType
}

// 2 weeks ago
const isRecentlyActiveAuthor = (lastLogins: any, suggestedThought: TextPost): boolean =>
  Date.now() - lastLogins[suggestedThought.authorId] < 28 * 24 * 60 * 60 * 1000

const filterTopThreeSuggestionsPerAuthor = (
  suggestions: SuggestedThoughtInfo[]
): SuggestedThoughtInfo[] => {
  const authorCounts: { [key: string]: number } = {} // Object to track the count of included suggestions for each author
  return suggestions.filter((suggestion) => {
    const authorId = suggestion.suggestedThought.authorId

    // If the author's count is not tracked yet, initialize it
    if (!authorCounts[authorId]) authorCounts[authorId] = 0

    // If the author's count is less than 3, increment and include the suggestion
    if (authorCounts[authorId] < 3) {
      authorCounts[authorId]++
      return true
    }

    // If the author already has 3 suggestions included, exclude this suggestion
    return false
  })
}
