import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import "./SimpleApp.css"
import Create from "./Create/Create"
import PostList from "./PostList/PostList"
import SimpleAppPostContext, {
  InboundEdgesType,
  PostMap,
  SimpleAppViewOptions,
} from "./SimpleAppPostContext"

import {
  child,
  equalTo,
  get,
  getDatabase,
  limitToLast,
  onValue,
  orderByChild,
  query,
  ref,
  set,
} from "firebase/database"
import AuthContext from "../../ReactContexts/AuthContext"
import FirebaseWriter from "../../Firebase/FirebaseWriter"
import {
  ConnectionKind,
  SingleConnectionUpdateForAPerson,
  TextPost,
} from "../../ReactContexts/PostContext"
import { thoughtIsReply } from "../../Firebase/ReplyUtilities"
import { PersonFirebaseBucket } from "../../Firebase/FirebaseDatabaseInterfaces"
import ConnectionInbox from "./Inbox/ConnectionInbox/ConnectionInbox"
import { filterArrayToJustHaveUniqueItems } from "./Inbox/Inbox"
import { appShortcutInstructionsKey } from "../App"
import { isAppShortcut } from "../.."
import LogRocket from "logrocket"
import { getEdgeAuthor } from "../../Logic/ConnectionLogic"

// Attempt to parse posts from local storage
let parsedPosts: PostMap | undefined
const stringPosts = localStorage.getItem("posts")
if (stringPosts) {
  try {
    parsedPosts = JSON.parse(stringPosts)
  } catch (error) {
    console.error("Failed to parse posts from local storage", error)
  }
}

// Define local storage keys
const appViewLocalStorageKey = "voices-app-view"
const lastActiveLocalStorageKey = "voices-last-active"

// Attempt to retrieve view options from local storage
let providedAppView: SimpleAppViewOptions | undefined
const storedAppView = localStorage.getItem(appViewLocalStorageKey)
if (storedAppView) {
  providedAppView = storedAppView as SimpleAppViewOptions
}

// Attempt to retrieve last active time from local storage
let providedLastActive: number | undefined
const storedLastActive = localStorage.getItem(lastActiveLocalStorageKey)
if (storedLastActive) {
  providedLastActive = Number(storedLastActive)
}

// Initialize Firebase writer
//Don't initialize this yet
export let simpleBackendWriter: FirebaseWriter = null
//FOR BUGS
//new FirebaseWriter()

const countNewerEdgesInternal = (
  edgeArr: SingleConnectionUpdateForAPerson[],
  lastPeakedAllUpdates: number
): number => {
  if (typeof lastPeakedAllUpdates !== "number" || !edgeArr) {
    return 0
  }

  return edgeArr.filter((edge) => edge.timestamp > lastPeakedAllUpdates).length
}
function SimpleApp() {
  const [postMap, setPostMap] = useState<PostMap>()
  const [allMyPosts, setAllMyPosts] = useState<TextPost[]>()
  const [rootPosts, setRootPosts] = useState<TextPost[]>()
  const [postsLoading, setPostsLoading] = useState<boolean>(true)

  let { person } = useContext(AuthContext)
  useEffect(() => {
    const db = getDatabase()
    // Create a reference to the "nodes" location in the database
    const nodesRef = ref(db, "p/forum/nodes")
    // Query the database to get only the nodes with a specific authorId
    const nodesByAuthor = query(
      nodesRef,
      orderByChild("authorId"),
      equalTo(person.uid),
      limitToLast(100)
    )
    const lastLoginRef = ref(db, "p/forum/people/" + person.uid + "/timestamps/lastLogin")
    set(lastLoginRef, Date.now())

    // Listen for value changes on the query result
    onValue(nodesByAuthor, (snapshot) => {
      // Get an array of the node objects that match the query
      setPostsLoading(false)
      const nodesMap: PostMap = snapshot.val()

      // Add the nodes to the cache
      for (let nodeId in nodesMap) {
        FirebaseWriter.addToCache(nodeId, nodesMap[nodeId] as TextPost)
      }

      // Set the state with the array of nodes
      setPostMap(nodesMap)
      const definedPosts =
        Object.values(nodesMap ?? {})
          .filter((node) => node.text)
          .reverse() ?? [] //reverse so it's chronlogical

      setAllMyPosts(definedPosts)
      const newRootPosts = definedPosts.filter((node) => !thoughtIsReply(node as TextPost))

      setRootPosts(newRootPosts)
    })
  }, [])

  //initialize simple backend writer
  useEffect(() => {
    if (!simpleBackendWriter?.databaseRef && person) {
      const db = getDatabase()
      const databaseRef = ref(db, `p/forum/`)
      //const person name? //TODO come back to this , initialize personBucket if doesn't already exist, etc.
      simpleBackendWriter.initialize(databaseRef, person.uid, person.email, undefined)
    }

    if (person)
      LogRocket.identify(person?.uid, {
        name: person?.displayName,
        email: person?.email,

        // Add your own custom user variables here, ie:
        // subscriptionType: 'pro'
      })
  }, [person])

  //personBucket
  const [personBucket, setPersonBucket] = useState<PersonFirebaseBucket>()

  const [inboundEdges, setInboundEdges] = useState<InboundEdgesType>()
  //whenever inbound edges changes, calculate new number
  useEffect(() => {
    countNewEdges(
      inboundEdges,
      setEdgeCount,
      person.uid,
      personBucket?.timestamps?.lastPeakedAllUpdates
    )
  }, [inboundEdges, person?.uid, personBucket?.timestamps?.lastPeakedAllUpdates])
  const [edgeCount, setEdgeCount] = useState<number>(0)
  useEffect(() => {
    const inboundEdgesRef = child(
      simpleBackendWriter?.databaseRef,
      `/people/${person.uid}/connections/inbound`
    )
    const inboundConnectionsRef = query(
      inboundEdgesRef,
      orderByChild("edgeKind"),
      equalTo(ConnectionKind.CONNECTION),
      limitToLast(50)
    )

    const inboundRepliesRef = query(
      inboundEdgesRef,
      orderByChild("edgeKind"),
      equalTo(ConnectionKind.REPLY),
      limitToLast(50)
    )

    get(inboundConnectionsRef)
      .then((snap) => {
        if (snap.exists()) {
          const theValue = snap.val()
          setInboundEdges((x) => {
            // Update the state with the new value
            const updatedEdges = { ...x, [ConnectionKind.CONNECTION]: theValue }
            // Now, with the updated value, set the edge count

            return updatedEdges
          })
        } else setInboundEdges({})
      })
      .catch((e) => console.error("error with inbound edges", e))

    get(inboundRepliesRef)
      .then((snap) => {
        if (snap.exists()) {
          const theValue = snap.val()
          setInboundEdges((x) => {
            // Update the state with the new value
            const updatedEdges = { ...x, [ConnectionKind.REPLY]: theValue }
            // Now, with the updated value, set the edge count

            return updatedEdges
          })
        }
      })
      .catch((e) => console.error("error with inbound edges", e))
  }, [person, personBucket?.connections?.inbound, personBucket?.timestamps?.lastPeakedAllUpdates])

  useEffect(() => {
    const db = getDatabase()
    if (!person || !("uid" in person)) return
    const personRef = ref(db, "p/" + "forum" + "/people/" + person?.uid) //nodes are posts
    //get person data on the front end
    onValue(personRef, (data) => {
      let personVal: PersonFirebaseBucket
      if (data.exists()) {
        personVal = data.val()
        setPersonBucket(personVal)
      } else {
        //otherwise, initialize
        const personBucket: PersonFirebaseBucket = {
          enteredDoor: null,
          personName: null,
          personEmail: person.email,
        }
        set(personRef, personBucket)
      }
    })

    //grab firebase display name etc.
  }, [person])

  //get person name if doesn't exist
  useEffect(() => {
    //check if name exists
    const nameDoesntExist = personBucket && !personBucket?.personName
    const db = getDatabase()
    if (!person || !("uid" in person)) return

    const personNameRef = ref(db, "p/" + "forum" + "/people/" + person?.uid + "/personName") //nodes are posts
    if (nameDoesntExist) {
      const requestPersonName = () => {
        let name = ""
        while (!name) {
          name = window.prompt("What's your forest name?") ?? ""
        }
        return name
      }
      const theirName = requestPersonName()
      //update personBucket
      set(personNameRef, theirName)
      //update firebase writer
    }
    if (personBucket?.personName) simpleBackendWriter.setName(personBucket.personName)
  }, [personBucket])

  //initialize the connected/bookmarked posts
  const [bookmarkedPosts, setBookmarkedPosts] = useState<TextPost[]>()

  const [editorIsEmpty, setEditorIsEmpty] = useState(true)
  const [editorIsFocused, setEditorIsFocused] = useState(false)
  const [myPostsLoading, setMyPostsLoading] = useState(true)

  const [thoughtIdToExpand, setThoughtIdToExpand] = useState<string>()
  const [newThoughtEmbeddingsLoading, setNewThoughtEmbeddingsLoading] = useState(false)
  //1000 seconds threshold

  const [appView, setAppView] = useState<SimpleAppViewOptions>(
    Date.now() - Number(localStorage.getItem(lastActiveLocalStorageKey)) < 1000000
      ? (localStorage.getItem(appViewLocalStorageKey) as SimpleAppViewOptions) ?? "write"
      : "write"
  )
  useEffect(() => {
    localStorage.setItem(lastActiveLocalStorageKey, String(Date.now()))
    localStorage.setItem(appViewLocalStorageKey, appView)
  }, [appView])

  //PWA dialog
  // return <div id="divInstallApp"></div>

  //commenting out for now, not effective.
  // useEffect(() => {
  //   if (!isAppShortcut && !localStorage.getItem(appShortcutInstructionsKey)) {
  //     window.alert(`Plexus works best as an iPhone app! \n\nHere are three steps to get it...`)
  //     window.alert(
  //       `On your iPhone...\n\n1. Go to plexus.earth on Safari\n2. Click Safari's share icon\n 3. Click "Add to Home Screen"`
  //     )
  //     window.alert("That's it :)")
  //     localStorage.setItem(appShortcutInstructionsKey, "done!!")
  //   }
  // }, [])
  return (
    <SimpleAppPostContext.Provider
      value={{
        posts: postMap,
        setPosts: setPostMap,
        rootPosts,
        setRootPosts,
        editorIsEmpty,
        setEditorIsEmpty,
        thoughtIdToExpand,
        setThoughtIdToExpand,
        newThoughtEmbeddingsLoading,
        setNewThoughtEmbeddingsLoading,
        appView,
        setAppView,
        personBucket,
        setPersonBucket,
        editorIsFocused,
        setEditorIsFocused,
        bookmarkedPosts,
        setBookmarkedPosts,
        postsLoading,
        setPostsLoading,
        myPostsLoading,
        setMyPostsLoading,
        inboundEdges,
        setInboundEdges,
        edgeCount,
        setEdgeCount,
        allMyPosts,
        setAllMyPosts,
      }}
    >
      <div className="App">
        <div className="panel-container">
          {false ? (
            <div>Show Tutorial</div>
          ) : (
            <div className="panel">
              <div className="app-view-toggle row">
                {["write", "thoughts", "inbox"].map((view: SimpleAppViewOptions) => (
                  <div
                    key={view}
                    className={`toggle-option ${appView === view ? "selected" : ""} ${
                      view === "inbox" && edgeCount > 0 ? "bold-inbox" : ""
                    }`}
                    onClick={() => setAppView(view)}
                  >
                    {(view === "write" ? "EXPRESS" : view.toUpperCase()) +
                      (view === "inbox" && edgeCount > 0 ? ` ${edgeCount}` : "")}
                  </div>
                ))}
              </div>
              {appView === "thoughts" && <PostList />}
              {appView === "write" && (
                <>
                  <br />
                  <Create />
                </>
              )}
              {appView === "inbox" && <ConnectionInbox />}
            </div>
          )}
        </div>
      </div>
    </SimpleAppPostContext.Provider>
  )
}

export default SimpleApp

function countNewEdges(
  inboundEdges: InboundEdgesType,
  setNumber: Dispatch<SetStateAction<number>>,
  thisPersonId: string,
  lastPeakedTime: number
): number {
  const incomingReplies = Object.values(inboundEdges?.REPLY ?? {})
  const incomingListens = Object.values(inboundEdges?.CONNECTION ?? {})

  const withoutSelfActions = [...incomingReplies, ...incomingListens].filter(
    (e) => getEdgeAuthor(e) !== thisPersonId
  )
  const allIncoming = filterArrayToJustHaveUniqueItems(
    withoutSelfActions,
    (a, b) => a?.sourceId === b?.sourceId && a?.targetThoughtId === b?.targetThoughtId
  )
  const numUnseen = countNewerEdgesInternal(allIncoming, lastPeakedTime)
  setNumber(numUnseen)
  return numUnseen
}
