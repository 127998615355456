import { DownloadSimple, Link, Pause, PencilSimple, Play, TrashSimple } from "@phosphor-icons/react"
import React, { useContext, useEffect, useRef, useState } from "react"
import { createEditor } from "slate"
import { withHistory } from "slate-history"
import { withReact } from "slate-react"
import "./FeedItem.css"
import { getTwitterLikeAbbreviation } from "../../../util"
import { ConnectionKind, TextPost } from "../../../ReactContexts/PostContext"
import AuthContext from "../../../ReactContexts/AuthContext"
import { simpleBackendWriter } from "../SimpleApp"
import { NavigateFunction, useNavigate, useParams } from "react-router-dom"
import { useAudioPlayer } from "../AudioPlayerProvider"
import { handleConnectionClick } from "./SuggestedTextPost/SuggestedTextPost"
import usePersonName from "../../CustomReactHooks/UsePersonName"
import { findDirectedEdgeBetweenPosts } from "../../../Logic/ConnectionLogic"
import usePostTitle from "../../CustomReactHooks/UsePostTitle"
import usePostConfirmedDoesntExist from "../../CustomReactHooks/UsePostConfirmedDoesntExist"
import useReplyThoughtIds from "../../CustomReactHooks/UseReplyThoughts"
import FirebaseWriter from "../../../Firebase/FirebaseWriter"
import usePostIsListened from "../../CustomReactHooks/usePostIsListened"
import Button from "../Button/Button"
import { frontendAppendPost, AppendsPost } from "../Create/Create"
import AudioRecorderButton from "../../Editor/AudioRecorderButton"

const MaxWordsToShow = 0

export const abbreviateByWords = (content: string, maxWords = MaxWordsToShow) => {
  if (maxWords == 0) return ""
  const words = content.split(" ")
  if (words.length > maxWords) {
    return `${words.slice(0, maxWords).join(" ")}...`
  }
  return content
}
const FeedItem = ({
  id,
  text,
  expanded,
  alreadyConnected,
  timestamp,
  audioUrl,
  post,
  fromPost: fromPost,
  isBreadcrumb,
}: {
  id: string
  text: string
  expanded?: boolean
  alreadyConnected?: boolean
  timestamp: number
  audioUrl?: string
  post?: TextPost
  fromPost?: TextPost
  isBreadcrumb?: true
}) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef(null)
  const { person } = useContext(AuthContext)
  const { placeId, thoughtId } = useParams()
  const { playAudio, currentlyPlayingId, setCurrentlyPlayingId } = useAudioPlayer()
  const [isExpanded, setIsExpanded] = useState(false)
  const [replyEditorFocused, setReplyEditorFocused] = useState(true)
  const [replyEmbeddingLoading, setReplyEmbeddingLoading] = useState(false)
  const [editor, _setEditor] = useState(() => withHistory(withReact(createEditor())))
  const navigate = useNavigate()
  const hasAudioURL = audioUrl ?? post?.audioUrl

  const { replyIds, setReplyIds } = useReplyThoughtIds(post)

  const isTruncatable = (content: string) => {
    const words = content.split(" ")
    return words.length > MaxWordsToShow
  }

  const setNewTitle = (newTitle: string) => {
    const trimmedTitle = newTitle.replace(/\s+$/, "")
    simpleBackendWriter.setSummaryTitle(trimmedTitle, post.id)
  }

  //Custom hooks
  const updatedAuthorName = usePersonName(simpleBackendWriter, post.authorId, post?.authorName)
  const updatedPostTitle = usePostTitle(simpleBackendWriter, post.id, post?.title)
  const postConfirmedDoesntExist = usePostConfirmedDoesntExist(simpleBackendWriter, post.id)

  //gets updated automatically
  const thoughtIsListened = usePostIsListened(
    post.id,
    person?.uid,
    simpleBackendWriter,
    post?.audioUrl,
    post?.authorId,
    post?.title
  )
  const listenedAlready = !post.audioUrl || thoughtIsListened || person?.uid === post?.authorId

  useEffect(() => {
    setIsPlaying(currentlyPlayingId === id)
  }, [currentlyPlayingId, id])

  useEffect(() => {
    setCurrentlyPlayingId(null)
  }, [thoughtId])

  // Handle the side effect of stopping audio and removing event handlers when the component is unmounted
  useEffect(() => {
    const audio = audioRef.current
    if (audio) {
      audio.addEventListener('timeupdate', handleTimeUpdate)
      audio.addEventListener('ended', handleAudioEnd)
    
      return () => {
        audio.pause()
        audio.removeEventListener('timeupdate', handleTimeUpdate)
        audio.removeEventListener('ended', handleAudioEnd)
      }
    }
  }, [])

  const handleTimeUpdate = (event) => {
    const audio = audioRef.current;
    if (audio) {
      const duration = audio.duration;
      const currentTime = audio.currentTime;
      const percentageListened = (currentTime / duration) * 100;
      
      if (percentageListened >= 30) {
        if (person) handleConnectionClick(event, fromPost, post, alreadyConnected, person?.uid)
        audio.removeEventListener('timeupdate', handleTimeUpdate);
      }
    }
  }

  const stopAudio = () => {
    audioRef.current?.pause()
    setIsPlaying(false)
    if (currentlyPlayingId === id) {
      setCurrentlyPlayingId(null)
    }
  }

  const handleAudioEnd = () => {
    const parentFeedItem = audioRef.current.closest(".textPost")
    const allFeedItems = document.querySelectorAll(".textPost")
    const parentFeedItemIndex = Array.from(allFeedItems).indexOf(parentFeedItem)
    const nextFeedItem = allFeedItems[parentFeedItemIndex + 1]
    const nextFeedItemPlayButton: HTMLButtonElement = nextFeedItem?.querySelector(".play-button")
    if (nextFeedItemPlayButton) {
      nextFeedItem.scrollIntoView({ behavior: "smooth", block: "center"})
      nextFeedItemPlayButton.click()
    } else {
      stopAudio()
    }
  }

  const toggleAudioPlay = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()

    if (!audioRef.current) return
    if (isPlaying) {
      stopAudio()
    } else {
      playAudio(audioRef.current, id) // Pass the id to globally track this audio
    }
  }

  const handleAppendReply: AppendsPost = (transcription?: string, audioURL?: string) => {
    return frontendAppendPost(
      post,
      transcription,
      audioURL,
      setReplyEmbeddingLoading,
      editor,
      undefined,
      setReplyEditorFocused,
      undefined
    ).then((newPost) => {
      setReplyIds((prev) => [...prev, newPost?.id])
      return newPost
    })
  }

  const downloadAudio = () => {
    if (!post?.audioUrl) return

    fetch(post?.audioUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = url
        a.download = `plexus_${post?.id}.mp4`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })
      .catch((error) => console.error("Error in downloading file:", error))
  }

  const content = text
  const displayContent = isExpanded ? content : abbreviateByWords(content)?.trim()
  const contentIsTruncatable = isTruncatable(content)
  const numWords = content.split(" ").length + 1

  const isReply = post.isReply
  const isReplyToParent =
    (post && fromPost
      ? findDirectedEdgeBetweenPosts(post, fromPost, ConnectionKind.REPLY)
      : false) ||
    ((expanded || isBreadcrumb) && post.isReply) //in this scenario, it's the focused thought
  if (postConfirmedDoesntExist) return <span id={"post-doesnt-exist-" + post.id}></span>
  return (
    <div
      key={id + post.id + (fromPost?.id ?? "") + (thoughtId ?? "")}
      className={
        "textPost " +
        (expanded ? "expanded" : "") +
        (listenedAlready ? " listenedAlready " : "") +
        (isBreadcrumb ? " breadcrumb " : "") +
        (isPlaying ? " playing" : "")
      }
      onClick={(event) => {
        //record in local storage where we were before
        // otherPost is the previously focused one? what is otherPost?
        handlePostNavigationClick(event, navigate, simpleBackendWriter, post, fromPost)
      }}
    >
      <div className="two-columns">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            className="post-header"
            style={{
              display: "flex",
              alignItems: "baseline",
              color: "rgb(200,200,200)",
            }}
          >
            {isReplyToParent ? (
              <>[reply to ^]&nbsp;</>
            ) : isReply ? (
              <>[reply{fromPost ? " from elsewhere" : ""}]&nbsp;</>
            ) : (
              ""
            )}

            {post?.authorName ? ( // Check if authorEmail is provided in the post object
              <div
                onClick={() => {
                  if (post?.authorId === person?.uid) {
                    const newName = window.prompt(
                      "Update display name for future posts?",
                      updatedAuthorName
                    )
                    if (newName) {
                      simpleBackendWriter.setName(newName).then(() => {
                        window.alert(
                          "Display name updated to " + newName + " for all future posts!"
                        )
                      })
                    }
                  }
                }}
                className="author-name-in-post"
              >
                {updatedAuthorName}
              </div>
            ) : (
              <></>
            )}
            <div style={{ color: "rgb(200,200,200)", marginBottom: ".5ex" }}>
              &thinsp;
              {getTwitterLikeAbbreviation(new Date(timestamp))}
            </div>
            {post?.authorId === person?.uid ? (
              <div
                className="delete-button"
                onClick={(event) => {
                  event.stopPropagation()
                  // Call delete function
                  let response = window.confirm("Delete this post?")
                  if (response) {
                    simpleBackendWriter.deletePost(post, placeId)
                  }
                }}
              >
                <TrashSimple className="delete-post-trash" size={16} />
              </div>
            ) : (
              <></>
            )}
            <div
              className="delete-button"
              onClick={(event) => {
                event.stopPropagation()
                navigator.clipboard
                  .writeText(`https://plexus.earth/idea/${post.id}`)
                  .then(() =>
                    window.alert(
                      "Wooooo! Copied link to your clipboard.\n\nYou can send this to anyone, even if they're not on Plexus yet."
                    )
                  )
              }}
            >
              <Link className="delete-post-trash" size={16} />
            </div>
            {person?.uid === post?.authorId ? (
              <div
                className="delete-button download-audio-button"
                onClick={(event) => {
                  event.stopPropagation()
                  downloadAudio()
                }}
              >
                <DownloadSimple className="delete-post-trash" size={16} />
              </div>
            ) : (
              <></>
            )}
            {person?.uid === post?.authorId ? (
              <div
                className="delete-button"
                onClick={(e) => {
                  e.stopPropagation()
                  if (post?.authorId !== person?.uid) return
                  const theNewTitle = window.prompt(
                    "Title your thought something amazing.",
                    updatedPostTitle?.trim() ?? "untitled"
                  )
                  setNewTitle(theNewTitle)
                }}
              >
                <PencilSimple className="delete-post-trash" size={16} />
              </div>
            ) : (
              <></>
            )}
          </div>
          {/* Audio button container */}

          <div className={"textpost-title" + (post.authorId !== person?.uid ? "" : " mine")}>
            {" "}
            {post.isReply && !post?.audioUrl
              ? ""
              : updatedPostTitle?.length > 1
              ? updatedPostTitle
              : abbreviateByWords(post.text, 5) ?? "Untitled"}
          </div>
          {/* get rid of text */}

          <div
            style={{ cursor: "pointer" }}
            className={"mini-truncated-display"}
            onClick={(event) => {
              event.stopPropagation()
              setIsExpanded((prev) => !prev)
            }}
          >
            {displayContent}
            <span>
              {contentIsTruncatable ? (
                isExpanded ? (
                  ` [ - ]`
                ) : (
                  <span style={{ textDecoration: "none", color: "rgb(200,200,200)" }}>{` ${
                    numWords - MaxWordsToShow
                  } words `}</span>
                )
              ) : (
                ""
              )}
            </span>
          </div>

          {/* Hidden audio element */}
          {hasAudioURL && <audio ref={audioRef} src={audioUrl} />}
        </div>

        {hasAudioURL ? (
          <div className="button-column">
            {/* play button */}
            <Button
              className="play-button"
              key={post.id + "-playbutton"}
              onClick={(event) => {
                event.stopPropagation()
                toggleAudioPlay(event)
              }}
              isDeprioritised={listenedAlready}
            >
              {isPlaying ? (
                <Pause size={16} color="black" weight="fill" />
              ) : (
                <Play size={16} color="black" weight="fill" />
              )}
            </Button>

            {/* reply button */}
            {person && (
              <AudioRecorderButton
                {...{
                  appendPost: handleAppendReply,
                  isReply: true,
                  isSmall: true,
                  isDeprioritised: !listenedAlready || !!replyIds?.length,
                }}
              />
            )}
          </div>
        ) : null}
      </div>
      {/* possibly show recorder */}
    </div>
  )
}

export default FeedItem

/**
 * Whenever someone clicks on a post
 * @param event
 * @param navigate
 * @param backendWriter
 * @param toPost
 * @param fromPost
 */
const handlePostNavigationClick = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  navigate: NavigateFunction,
  backendWriter: FirebaseWriter,
  toPost: TextPost,
  fromPost?: TextPost,
  hasUpdates?: boolean
) => {
  //navigate to the post
  event.stopPropagation()
  console.log(navigate)
  navigate(`/idea/${toPost.id}${hasUpdates ? "?updated=true" : ""}`)
  console.log("setThoughtIdToExpand", toPost.id)

  //create a traversal edge
  if (fromPost) backendWriter.stepToExistingThought(toPost, fromPost, undefined, undefined)
}
