import React, { useContext } from "react"
import ExistingPaths, { NextOrPrev } from "../ExistingPaths/ExistingPaths"
import LocalForestContext from "../LocalForestContext"
import { keyForQueen } from "../LocalForestView"
import NewPathButton from "../NewPathButton/NewPathButton"

const NextPaths = () => {
  const { nextPaths, queenThought } = useContext(LocalForestContext)
  return (
    <div
      className={"existing-paths-container " + NextOrPrev.NEXT}
      key={"all-next-paths-for-queen-" + keyForQueen(queenThought)}
    >
      <NewPathButton />
      <ExistingPaths
        nextOrPrev={NextOrPrev.NEXT}
        paths={nextPaths}
        key={"next-existing-paths-for-queen-" + keyForQueen(queenThought)}
      />
    </div>
  )
}

export default NextPaths
