import { useEffect, useState } from "react"
import { get, getDatabase, onValue, ref, set } from "firebase/database"
import FirebaseWriter from "../../Firebase/FirebaseWriter"
import { PersonFirebaseBucket } from "../../Firebase/FirebaseDatabaseInterfaces"
import { User } from "firebase/auth"

//Draft of Forest Name hook
function useForestName(person: User) {
  const [theirName, setTheirName] = useState("")

  useEffect(() => {
    const db = getDatabase()
    if (!person || !("uid" in person)) return

    const personNameRef = ref(db, "p/" + "forum" + "/people/" + person.uid + "/personName")
    let name: string
    onValue(personNameRef, (snap) => {
      if (snap.exists()) {
        name = snap.val()
        setTheirName(snap.val())
      } else {
        const requestPersonName = () => {
          let tempName: string
          while (!tempName) {
            tempName =
              window.prompt("What should we call you? (We recommend your favorite emoji.)") ?? ""
          }
          return tempName
        }
        name = requestPersonName()
        setTheirName(name)
        set(personNameRef, name)
      }
    })
  }, [person])

  return theirName
}

export default useForestName
