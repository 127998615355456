import { child, get } from "firebase/database"
import path from "path"
import { getSlateValueFromText } from "../Components/AdminStuff/components/sendServerData"
import getSuggestionsForOneThought from "../Components/Feed/GetSuggestedThoughts"
import { filterArrayToJustHaveUniqueItems } from "../Components/SimplePlexusComponents/Inbox/Inbox"
import FirebaseWriter, {
  makePost,
  thoughtIdsTraversedThisSession,
} from "../Firebase/FirebaseWriter"
import { FlatEdgeMap, getFlatDirectedEdgeMap } from "../Firebase/ReplyUtilities"
import { getEdgeAuthor } from "../Logic/ConnectionLogic"
import {
  AncestorThought,
  ConnectionKind,
  DirectionalEdgeMap,
  EdgeKind,
  TextPost,
  WhichPlexusInterface,
} from "../ReactContexts/PostContext"
import { ensureNewPathEmailForest } from "../SendEmail"
import { forestWriter } from "./ForestApp"
import {
  ForestEdgeType,
  ForestPathType,
  ForestThoughtType,
  InboundOrOutbound,
  SAMPLE_FOREST_EDGE,
  SAMPLE_FOREST_PATH,
  SAMPLE_FOREST_PATH_ARRAY,
  SAMPLE_FOREST_THOUGHT,
  ThoughtType,
} from "./ForestTypes"
import { NextOrPrev } from "./LocalForestView/ExistingPaths/ExistingPaths"

class ForestWriter extends FirebaseWriter {
  findEdgeFromThisPerson?(edges: ForestEdgeType[]): ForestEdgeType | undefined {
    return edges?.filter((edge) => getEdgeAuthor(edge) === this.personId)[0] ?? undefined
  }
  async getCaptionFromEdges(edges: ForestEdgeType[], nextOrPrev: NextOrPrev): Promise<string> {
    let authorIds = {}
    edges.forEach((edge) => (authorIds[getEdgeAuthor(edge)] = true))
    const authorIdArr = Object.keys(authorIds ?? {})

    let authorNamePromises = []
    authorIdArr.forEach((authorId) => {
      const nodeRef = child(forestWriter.databaseRef, `people/${authorId}/personName`)
      authorNamePromises.push(
        get(nodeRef).then((snapshot) => {
          if (snapshot.exists()) {
            return snapshot.val()
          }
          return undefined
        })
      )
    })
    const filteredNamePromises = authorNamePromises.filter((e) => e)
    const names = await Promise.all(filteredNamePromises)
    const concatenatedString = names.join(", ")

    return (
      `${nextOrPrev.toLocaleLowerCase()}.step by ` +
      (edges.length ? `${concatenatedString} ` : `forest god`)
    )
  }

  //Converted to a custom hook getForestThought
  // getQueenThoughtFromId(id: string): ForestThoughtType {
  //   const thought = FirebaseWriter.queryByIds(id)
  //   return SAMPLE_FOREST_THOUGHT
  // }

  async getPrevPaths(queenThought: ForestThoughtType): Promise<ForestPathType[]> {
    const humanPaths = await this.getHumanPaths(queenThought, NextOrPrev.PREV)
    //refactor this soon
    // const thoughts = humanPaths.map((e) => e.thought)
    // const thoughtEdgeMap = this.thoughtAllEdgeMap(queenThought, thoughts)
    // const unoriginalIds = this.unoriginalIds(
    //   thoughtEdgeMap,
    //   convertPrevNextToInboundOutbound(NextOrPrev.PREV)
    // )
    // const ogPrev = humanPaths.filter((e) => !unoriginalIds.includes(e.thought.id))
    return humanPaths
  }

  async getNextPaths(queenThought: ForestThoughtType): Promise<ForestPathType[]> {
    if (!queenThought) return
    const humanPaths = await this.getHumanPaths(queenThought, NextOrPrev.NEXT)
    const semanticPaths =
      queenThought.id === "forest-origin" ? [] : await this.getSemanticPaths(queenThought)

    const combinedPaths = [...humanPaths, ...semanticPaths]
    const uniquePaths = filterArrayToJustHaveUniqueItems(
      combinedPaths,
      (a: ForestPathType, b: ForestPathType) => a?.thought?.id === b?.thought?.id
    )
    //filter out ones that are OG previous
    //filter for ones that should uniquely appear next/prev
    //REFACTOR THIS
    // const thoughts = uniquePaths.map((e) => e.thought)
    // const thoughtEdgeMap = this.thoughtAllEdgeMap(queenThought, thoughts)
    // const unoriginalIds = this.unoriginalIds(
    //   thoughtEdgeMap,
    //   convertPrevNextToInboundOutbound(NextOrPrev.NEXT)
    // )
    // //filter out unoriginal
    // const ogNext = uniquePaths.filter((e) => !unoriginalIds.includes(e.thought.id))

    return uniquePaths
  }

  /**
   * Get paths taken by real people already from the queen thought
   * @param queenThought
   * @param nextOrPrev
   * @returns
   */
  async getHumanPaths(
    queenThought: ForestThoughtType,
    nextOrPrev: NextOrPrev
  ): Promise<ForestPathType[]> {
    const inboundOrOutbound = convertPrevNextToInboundOutbound(nextOrPrev)

    //1. get directed edges
    const edges: DirectionalEdgeMap = queenThought?.connections
      ? queenThought.connections[inboundOrOutbound]
      : {}

    //2. getNonQueenThoughts
    const thoughts = await this.getThoughtsFromFlatEdgeMap(edges)

    //3. convert thoughts to paths
    const paths = this.convertThoughtsToPaths(queenThought, thoughts, inboundOrOutbound)

    //ensure it's OG for the directed direction
    const ogPaths = this.filterPathsByOgDirection(queenThought, paths, inboundOrOutbound)

    //4. order paths
    const sortedPaths = this.sortPaths(ogPaths)

    return sortedPaths
  }

  //@TODO write this
  filterPathsByOgDirection(
    queenThought: ThoughtType,
    paths: ForestPathType[],
    inboundOrOutbound: InboundOrOutbound
  ): ForestPathType[] {
    const thoughtEdgeMap = this.thoughtAllEdgeMap(queenThought)
    const thoughtIds = Object.keys(thoughtEdgeMap ?? {})
    //use these edges to then calculate the earliest edge
    const filtered = paths.filter((path) => {
      const thoughtId = path.thought.id
      const allEdges = thoughtEdgeMap[thoughtId] ?? []
      if (!allEdges.length) return false

      //Get the earliest edge for this path, inbound or outbound
      const earliestEdge: ForestEdgeType = allEdges.reduce(
        (earliestTempEdge: ForestEdgeType, nextEdge: ForestEdgeType) => {
          if (!earliestTempEdge) return nextEdge
          return earliestTempEdge?.timestamp > nextEdge?.timestamp ? nextEdge : earliestTempEdge
        },
        undefined
      )

      //if direction is outbound and thought is target : good
      //if direction is inbound and thought is source: good
      //otherwise, bad
      const ogOutbound =
        inboundOrOutbound === "outbound" && earliestEdge?.targetThoughtId === thoughtId
      const ogInbound = inboundOrOutbound === "inbound" && earliestEdge?.sourceId === thoughtId

      //return all the ones that ARENT OG for the given direction
      return ogInbound || ogOutbound
    })

    return filtered
  }

  //more important to know which to filter out
  unoriginalIds(
    // paths: ForestPathType[],
    thoughtEdgeMap: ThoughtEdgeMap,
    inboundOrOutbound: InboundOrOutbound
  ): string[] {
    //for each path,
    // get it's earliest edge
    // keep if inbound/outbound alignment only
    const thoughtIds = Object.keys(thoughtEdgeMap ?? {})
    //the issue is that paths doesn't include all edges, only one direction. need to look for earliest edge in both directions
    const filtered = thoughtIds.filter((thoughtId) => {
      const allEdges = thoughtEdgeMap[thoughtId] ?? []
      if (!allEdges.length) return false
      const earliestEdge: ForestEdgeType = allEdges.reduce(
        (earliestTempEdge: ForestEdgeType, nextEdge: ForestEdgeType) => {
          if (!earliestTempEdge) return nextEdge
          return earliestTempEdge?.timestamp > nextEdge?.timestamp ? nextEdge : earliestTempEdge
        },
        undefined
      )

      //if direction is outbound and thought is target : good
      //if direction is inbound and thought is source: good
      //otherwise, bad
      const ogOutbound =
        inboundOrOutbound === "outbound" && earliestEdge?.targetThoughtId === thoughtId
      const ogInbound = inboundOrOutbound === "inbound" && earliestEdge?.sourceId === thoughtId

      //return all the ones that ARENT OG for the given direction
      return !(ogInbound || ogOutbound)
    })
    return filtered
  }

  //DRAFTED NOT TESTED
  async getThoughtsFromFlatEdgeMap(flatEdgeMap: DirectionalEdgeMap): Promise<ThoughtType[]> {
    //1. get ids
    const thoughtIds = Object.keys(flatEdgeMap ?? {})
    //2. query by ids
    const thoughts = await FirebaseWriter.queryByIds(thoughtIds)
    return thoughts
  }

  convertThoughtsToPaths(
    queenThought: ThoughtType,
    thoughts: ThoughtType[],
    inboundOrOutbound: InboundOrOutbound
  ): ForestPathType[] {
    const edges = queenThought?.connections ? queenThought.connections[inboundOrOutbound] ?? {} : {}

    //use edge map to convert each thought to a path
    const paths: ForestPathType[] = thoughts.map((thought) => {
      //Filter for only traversal edges
      const edgesArr = Object.values(edges[thought.id] ?? {}).filter(
        (edge) => edge?.edgeKind === ConnectionKind.TRAVERSAL
      )

      return { thought, edges: edgesArr }
    })
    // //important for calculating earliest edge
    // const reverseEdges = queenThought?.connections
    //   ? queenThought.connections[inboundOrOutbound === "outbound" ? "inbound" : "outbound"] ?? {}
    //   : {}
    // const reversePaths: ForestPathType[] = thoughts.map((thought) => {
    //   //Filter for only traversal edges
    //   const edgesArr = Object.values(reverseEdges[thought.id] ?? {}).filter(
    //     (edge) => edge?.edgeKind === ConnectionKind.TRAVERSAL
    //   )
    //   return { thought, edges: edgesArr }
    // })

    return paths
  }

  thoughtAllEdgeMap(queenThought: ThoughtType): ThoughtEdgeMap {
    const inboundEdges = queenThought?.connections ? queenThought.connections.inbound ?? {} : {}
    const outboundEdges = queenThought?.connections ? queenThought.connections.outbound ?? {} : {}

    const thoughtIds = [
      ...new Set([...Object.keys(inboundEdges ?? {}), ...Object.keys(outboundEdges ?? {})]),
    ]

    //use edge map to convert each thought to a path
    let map = {}
    thoughtIds.forEach((thoughtId) => {
      //Filter for only traversal edges
      const inboundEdgesArr = Object.values(inboundEdges[thoughtId] ?? {}).filter(
        (edge) => edge?.edgeKind === ConnectionKind.TRAVERSAL
      )
      const outboundEdgesArr = Object.values(outboundEdges[thoughtId] ?? {}).filter(
        (edge) => edge?.edgeKind === ConnectionKind.TRAVERSAL
      )
      map[thoughtId] = [...inboundEdgesArr, ...outboundEdgesArr]
    })

    return map
  }
  sortPaths(paths: ForestPathType[]) {
    const sortedPaths = paths.sort((pathA, pathB) => {
      return (this.getMostRecentEdgeTime(pathB) ?? 0) - (this.getMostRecentEdgeTime(pathA) ?? 0)
    })
    return sortedPaths
  }

  getMostRecentEdge(path: ForestPathType) {
    const mostRecentEdge = path.edges.reduce((tempRecentEdge, nextEdge) => {
      return tempRecentEdge?.timestamp > nextEdge?.timestamp ? tempRecentEdge : nextEdge
    }, undefined)
    return mostRecentEdge
  }
  getMostRecentEdgeTime = (path: ForestPathType) => this.getMostRecentEdge(path)?.timestamp

  async getSemanticPaths(queenThought: ForestThoughtType): Promise<ForestPathType[]> {
    if (!queenThought) return []
    //1. get similar thoughts
    const similarThoughts = await (
      await getSuggestionsForOneThought([queenThought], "forum", true)
    ).map((e) => e.suggestedThought)

    //2. convert thoughts to paths
    const paths = this.convertThoughtsToPaths(queenThought, similarThoughts, "outbound")

    //filter out all semantic paths that have edges with the queen (these are accounted for in human existing paths)
    const freshSemanticPaths = paths.filter((e) => !e.edges || e.edges.length == 0)
    return freshSemanticPaths
  }

  async addNewPath(
    queenThought: ForestThoughtType,
    textOfNewThought: string
  ): Promise<ForestPathType> {
    // window.alert("Soon this button will add a new path from the queen thought.")
    const { postWithId, addPostPromise } = this.addForestThought(textOfNewThought)
    const thought = postWithId as ForestThoughtType
    //wait for add post to resolve
    await addPostPromise

    const replyEdge = this.addForestReplyEdge(queenThought, thought)
    const edge = await this.takeForestStep(queenThought, thought)
    const newPath = { thought, edges: [edge] }
    return newPath
  }

  addForestThought(
    text: string,
    isReply?: true,
    lineage?: AncestorThought[]
  ): { postWithId?: TextPost; addPostPromise?: Promise<any>; embeddingsPromise?: Promise<any> } {
    const result = this.addPost(
      getSlateValueFromText(text),
      text,
      "forum",
      isReply,
      lineage,
      undefined,
      WhichPlexusInterface.FOREST
    )
    return result
  }

  takeForestStep(
    queenThought: ForestThoughtType,
    newThought: ForestThoughtType,
    sendNotificationEmail?: boolean
  ): ForestEdgeType {
    const edge = this.addStepForest(newThought, queenThought)
    //send email if necessary
    if (sendNotificationEmail)
      ensureNewPathEmailForest(
        queenThought,
        newThought,
        forestWriter.personId,
        forestWriter.personName
      )
    return edge
  }

  //To kick off the forest
  addUniverseDefault() {
    const newPost: TextPost = makePost(
      "mischiefchief",
      "mischiefchief",
      "app@plexus.earth",
      "welcome to forest / mischief awaits",
      Date.now(),
      "forest-origin"
    ) as TextPost
    const result = this.addPostInternal(newPost, "forum", newPost.id)
    return result
  }
}

export default ForestWriter

function convertPrevNextToInboundOutbound(nextOrPrev: NextOrPrev): InboundOrOutbound | undefined {
  return nextOrPrev === NextOrPrev.NEXT
    ? "outbound"
    : nextOrPrev === NextOrPrev.PREV
    ? "inbound"
    : undefined
}

interface ThoughtEdgeMap {
  [thoughtId: string]: ForestEdgeType[]
}
