import { User } from "firebase/auth"
import { Navigate } from "react-router-dom"
import { backendWriter } from "../Components/App"
import ForestLanding from "./ForestLanding"
import LocalForestView from "./LocalForestView/LocalForestView"
// import "./ForestPrivateRoute.css"

const usernameLocationLocal = "plexus-anon-name" // "place-username"
export let usernamex = localStorage.getItem(usernameLocationLocal) ?? ""

/**
 * A page that redirects to landing page if not logged in, but enters plexus app if person is logged in
 * @param param0
 * @returns
 */
const ForestPrivateRoute = ({ person }: { person?: User }) => {
  //when is oriented is defined, run firebase function then delete it
  // switching out <FirebaseDataContainer /> for a new component
  return !!person ? <LocalForestView /> : <ForestLanding />
}

export default ForestPrivateRoute
