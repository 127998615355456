import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import LocalForestView from "./LocalForestView/LocalForestView"
import "./LocalForestWrapper.css"

//Reloads every time thought id changes
//this seems to work better than forcing a reload
// the key is essential
const LocalForestWrapper = () => {
  const { thoughtId } = useParams()
  useEffect(() => {
    console.log("url id", thoughtId)
  }, [thoughtId])
  return (
    <>
      <div className="black-background" />
      <LocalForestView key={"local-forest-view-for-" + thoughtId} />
    </>
  )
}

export default LocalForestWrapper
