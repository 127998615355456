import React, { useState } from "react"
import "./ForestLanding.css"
import ForestImage from "./ForestLanding.png"
import ForestMoving from "./trees_1.gif"
import BranchesBlurry from "./branchesfreddie.png"
import { ArrowLeft, ArrowRight } from "@phosphor-icons/react"
import { signInWithPopup } from "firebase/auth"
import { auth, provider } from "../Forest/ForestApp"
import { useNavigate } from "react-router-dom"

const ForestLanding = () => {
  const [entranceAppears, setEntranceAppears] = useState(true)
  const navigate = useNavigate()
  const handleSuccessSignIn = () => {
    // navigate("/")
    console.log("success in signiing in")
  }
  const googleLogin = () => {
    console.log("trying google login")
    signInWithPopup(auth, provider)
      .then(handleSuccessSignIn)
      .catch((e) => console.warn(e))
  }
  return (
    <div className="forest-landing">
      <div className="forest-text">
        <div>FOREST</div>
        <div className="forest-entrance" onClick={googleLogin}>
          log 🪵 in
        </div>
      </div>

      <div className="forest-landing forest-image"></div>
    </div>
  )
}

export default ForestLanding
