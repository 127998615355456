import path from "path"
import React, { useEffect, useState } from "react"

function LineBetweenDivs({
  id1,
  id2,
  strokeWidth,
  dashed,
}: {
  dashed?: true
  id1: string
  id2: string
  strokeWidth: number
}) {
  const [coordinates, setCoordinates] = useState({ x1: 0, y1: 0, x2: 0, y2: 0 })

  const updateCoordinates = () => {
    const element1 = document.getElementById(id1)
    const element2 = document.getElementById(id2)

    if (element1 && element2) {
      const rect1 = element1.getBoundingClientRect()
      const rect2 = element2.getBoundingClientRect()
      const parentOffset = element1.offsetParent.getBoundingClientRect()

      setCoordinates({
        x1: rect1.left + rect1.width / 2 - parentOffset.left,
        y1: rect1.top + rect1.height / 2 - parentOffset.top,
        x2: rect2.left + rect2.width / 2 - parentOffset.left,
        y2: rect2.top + rect2.height / 2 - parentOffset.top,
      })
    }
  }

  useEffect(() => {
    // Update coordinates initially and whenever id1 or id2 changes
    updateCoordinates()

    // Set up a scroll event listener to update coordinates when the divs scroll
    const nextPathsContainer = document.getElementsByClassName("existing-paths-container NEXT")[0]
    nextPathsContainer?.addEventListener("scroll", updateCoordinates)
    const prevPathsContainer = document.getElementsByClassName("existing-paths-container PREV")[0]
    prevPathsContainer?.addEventListener("scroll", updateCoordinates)

    // Clean up the event listener when the component unmounts or id1 or id2 changes
    return () => window.removeEventListener("scroll", updateCoordinates)
  }, [id1, id2])

  return (
    <svg
      style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: -1 }}
      key={id1 + id2 + "linesvg"}
    >
      <line
        x1={coordinates.x1}
        y1={coordinates.y1}
        x2={coordinates.x2}
        y2={coordinates.y2}
        stroke={shadeOfGray(60)}
        strokeWidth={Math.sqrt(strokeWidth) ?? 0}
        key={id1 + id2 + "line"}
        strokeDasharray={dashed ? "20,20" : ""}
      />
    </svg>
  )
}

export default LineBetweenDivs

export const shadeOfGray = (x: number) => `rgb(${1 * x}, ${x}, ${x})`
