import React, { useContext } from "react"
import { ForestPathType, ForestThoughtType } from "../../ForestTypes"
import ExistingPaths, { NextOrPrev } from "../ExistingPaths/ExistingPaths"
import LocalForestContext from "../LocalForestContext"
import { keyForQueen } from "../LocalForestView"

const PrevPaths = () => {
  const { prevPaths, queenThought } = useContext(LocalForestContext)
  return (
    <div className={"existing-paths-container PREV"} key={keyForQueen(queenThought) + "prev-paths"}>
      <ExistingPaths
        nextOrPrev={NextOrPrev.PREV}
        paths={prevPaths}
        key={keyForQueen(queenThought) + "prev-paths-inner "}
      />
    </div>
  )
}

export default PrevPaths
