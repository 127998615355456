import React, { useContext } from "react"
import { ForestThoughtType } from "../../ForestTypes"
import ForestThought from "../ForestThought/ForestThought"
import LocalForestContext from "../LocalForestContext"
import "./QueenThought.css"

const QueenThought = () => {
  const { queenThought } = useContext(LocalForestContext)
  return (
    <div className="queen-thought">
      {/* Queen Thought */}
      <ForestThought {...{ isQueen: true, thought: queenThought }} />
    </div>
  )
}

export default QueenThought
